<template>
  <div class="margin-navbar">
    <div class="d-flex flex-column w-100 font-body text-dark p-4">
      <h1 class="mt-3 mb-4">
        Osteopatia
      </h1>
      <p>
        Per meglio comprendere l’Osteopatia, partiamo dalla considerazione del
        corpo umano come un sistema "diviso" in varie parti (organi,tessuti,
        apparati) e al tempo stesso anche come un organismo unitario e
        interconnesso. L'osteopatia mira a <strong>ottenere un buon funzionamento di
        tutte le parti nel loro insieme</strong>.
      </p>
      <p>
        Un altro concetto fondamentale è il processo di autoguarigione del
        corpo. In seguito ad un evento patologico, il paziente si allontana dal
        proprio sato di salute, andando verso uno stato che si può definire di
        malattia. Obiettivo del trattamento osteopatico è <strong>rimettere l'organismo
        sulla "strada della guarigione"</strong> rimuovendo gli ostacoli che provocano
        l’insorgenza del malessere.
      </p>
      <p>
        Una buona integrazione e armonia delle strutture corporee da come
        risultato l'efficenza delle funzioni. Un organismo ben funzionante
        garantisce una buona salute alle strutture che lo compongono.
      </p>
      <h4 class="mt-3 mb-2">Cosa tratta</h4>
      <p>
        Dolori articolari (dei più svariati distretti corporei come
        ad esempio cervicale, lombare, spalle e braccia, mani, piedi, ginocchia,
        anche…), problemi viscerali o del sistema neurovegetativo ( problemi di
        digestione, insonnia, vertigini e capogiri, dolori mestruali..), sequele
        di traumi e incidenti (colpi di frusta, impatti craniali, della colonna
        o degli arti)
      </p>
    </div>
    <div class="d-flex w-100 justify-content-end p-5">
      <router-link to="/" tag="button" class="btn">
        Torna alla home
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

const image = require("@/assets/elia-osteopatia.jpg");
console.log(image);

export default {
  name: "Osteopatia",
  components: {},
  metaInfo: {
    title: "Elia Giovinazzo - Osteopatia",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        name: "description",
        content:
          "Studio di Osteopatia e Fisioterapia del Dott. Elia Giovinazzo. Professionista della salute a Genova. Riabilitazione, Trattamento e Prevenzione.",
      },
      {
        property: "og:title",
        content: "Elia Giovinazzo - Osteopatia  e Fisioterapia - Genova",
      },
      { property: "og:site_name", content: "Elia Giovinazzo" },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" },
    ],
  },
  data() {
    return {
      backgroundImage: 'url("' + image + '")',
    };
  },
};
</script>

<style scoped></style>
